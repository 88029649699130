<template>
    <div>
        <div class="bg">
            <!-- banner -->
            <div class="banner_wrap">
                <div class="banner_bg"></div>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item><img src="../../assets/img/banner.png" alt=""></van-swipe-item>
                    <van-swipe-item><img src="../../assets/img/banner.png" alt=""></van-swipe-item>
                    <van-swipe-item><img src="../../assets/img/banner.png" alt=""></van-swipe-item>
                    <van-swipe-item><img src="../../assets/img/banner.png" alt=""></van-swipe-item>
                </van-swipe>
            </div>

            <!-- search -->
            <div class="search">
                <input type="text" placeholder="请输入药品名称">
            </div>

            <!-- 分类 -->
            <div class="item">
                <ul>
                    <li @click="goinfo('chineseMedicine')">
                        <img src="../../assets/img/myzy.png" alt="">
                        <p>名医中药</p>
                    </li>
                    <li @click="goinfo('westernMedicine')">
                        <img src="../../assets/img/zhxy.png" alt="">
                        <p>综合西药</p>
                    </li>
                </ul>
            </div>

            <!-- 切换 -->
            <div class="tabs">
                <van-tabs v-model="active" animated :swipeable='true'>
                    <van-tab title="中药批发">
                        <div class="tabs_item clearfloat">
                            <ul>
                                <li>零售中药房</li>
                                <li>骨科药房</li>
                            </ul>
                        </div>
                        <div class="all" @click="goinfo('allPharmacy')">全部</div>
                        <list></list>
                    </van-tab>
                    <van-tab title="西药房">
                        <div class="tabs_item clearfloat">
                            <ul>
                                <li>零售中药房</li>
                                <li>骨科药房</li>
                            </ul>
                        </div>
                        <div class="all" @click="goinfo('allPharmacy')">全部</div>
                        <list></list>
                    </van-tab>
                    <van-tab title="保健食品">
                        <div class="tabs_item clearfloat">
                            <ul>
                                <li>零售中药房</li>
                                <li>骨科药房</li>
                            </ul>
                        </div>
                        <div class="all" @click="goinfo('allPharmacy')">全部</div>
                        <list></list>
                    </van-tab>
                    <van-tab title="" disabled></van-tab>
                </van-tabs>
            </div>

            <!-- 悬浮窗 -->
            <div>
                <navbar :active="active"></navbar>
                <div class="shop_car" @click="goinfo('shoppingCar')">
                    <img src="../../assets/img/car.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import list from '@/components/pharmacy/list'
import navbar from '@/components/common/nav'
export default { 
    name:'pharmacy',
    components:{
        list,navbar
    },
    data(){
        return {
            active: 2,
        }
    },
    mounted(){},
    methods:{
        goinfo(names) {
            this.$router.push({ name: names });
        },
    },
}
</script>

<style lang='less' scoped>
/deep/.van-tabs__nav { background: #f5f5f5; }
/deep/.tabs .van-tab .van-tab__text { font-size: 18px !important; color: #444; font-weight: bold;} 
/deep/.van-tabs__line { background: #1DAEFC; width: 72px; height: 6px;} 
.bg { width: 100vw; height: auto; background: #f5f5f5;
    // 
    .banner_wrap { 
        height: 172px; position: relative;
        .banner_bg { width: 375px; height: 150px; background: linear-gradient(270deg,#6ef0ff 0%, #1daefc 17%); border-radius: 0 0 10% 10%;} 
        .my-swipe {
            position: absolute; top: 22px; left: 0; right: 0; margin: auto; width: 343px;
            .van-swipe-item { width: 343px; height: 150px; border-radius: 10px;
                img { width: 343px; height: 150px;} 
            }
        }
    }
    // 
    .search { width: 311px; height: 44px; border-radius: 22px; background: #fff url('../../assets/img/search.png') 16px 12px no-repeat; background-size: 20px 20px; margin: 16px auto;
        input { border: none; color: #444; height: 44px; line-height: 44px; font-size: 14px; margin-left: 50px; width: 220px;} 
    } 
    // 
    .item { width: 343px; height: 109px; background: #fff; margin: 0 auto; border-radius: 8px; 
        li { width: 50%; float: left; height: 109px; display: flex; justify-content: center; align-items: center; flex-direction: column;
            img { width: 50px; height: 50px; display: block;}
            p { font-size: 18px; color: #444; margin-top: 5px;}  
        } 
    }
    // 
    .tabs { margin-top: 16px;
        .tabs_item { 
            padding: 16px;
            li { font-size: 18px; color: #444; float: left; font-weight: bold; margin-right: 24px;}
        } 
        .all { text-align: right; font-size: 14px; color: #2d2d2d; padding: 0 16px 24px 0;} 
    }  
    .shop_car { position: fixed; right: 16px; bottom: 140px; width: 48px; height: 48px;
        img { width: 100%;} 
    } 
}

</style>